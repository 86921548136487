import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { MisurazioniComponent } from './pages/misurazioni/misurazioni.component';



const routes: Routes = [
  {path: '', component: HomeComponent, pathMatch: 'full'},
  {path: 'misurazione/:id', component: MisurazioniComponent, pathMatch: 'full'},
 

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [
    {provide: LocationStrategy, useClass: PathLocationStrategy}
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

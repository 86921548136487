<div class="px-4 py-8 md:px-6 lg:px-8" style="background-image: url('/assets/images/home_bg.png');">
  <div class="flex flex-wrap relative overflow-hidden items-center justify-center h-full">
    <div class="w-12 lg:w-6 flex">
      <div class="box text-center mt-4"
        style="background-color: rgba(220, 234, 254, 0.5); border-radius: 4%; width: 50%; margin: 0 auto; box-shadow: var(--home-card-shadow);">
        <!-- <div class="text-center mt-4">
          <img src="assets/loghi/logo_home_by_ates.png" alt="Ates" height="130" class="align-self-center mt-2">
        </div> -->
        <div class=" text-center mt-5 text-home text-4xl font-light font-['Poppins'] ml-4" style="font-weight: bold;">
          Benvenuto in FRAGILE!
        </div>
        <p class="px-2 py-2 line-height-3 text-center mx-auto text-sub_home" style="max-width:500px">Il portale per la
          gestione delle misurazioni interferometriche.</p>

        <div style="text-align: center; display: flex; justify-content: center; align-items: center; ">
          <button type="button" pButton label="ACCEDI" class=" mt-6 mb-2 "
            style="background-color: #0c3452; width: 40%;" (click)="loginWithRedirect()"></button>
        </div>
      </div>
    </div>
    <div class="w-12 lg:w-6 text-center lg:text-right overflow-hidden">
      <ng-lottie [options]="lottieConfig" (animationCreated)="animationCreated($event)"></ng-lottie>
    </div>
  </div>
  <div class="mt-6 text-align: center; ">
    <div class="text-center text-home font-light font-['Poppins']" style="font-weight: bold;">Powered by</div>
    <div style="text-align: center; display: flex; justify-content: center; align-items: bottom; ">
      <img src="assets/loghi/logo_latitude.png" alt="Image" height="40" class="align-self-center"
        style="cursor: pointer;" (click)="goToVertical()">
    </div>
  </div>
</div>

import { CommonModule, LocationStrategy, PathLocationStrategy } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { AppComponent } from "./app.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "../environments/environment";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { ButtonModule } from "primeng/button";
import { LottieModule } from 'ngx-lottie';

import player from 'lottie-web';

//PRIME NG MODULES
import { StyleClassModule } from "primeng/styleclass";
import { ChartModule } from 'primeng/chart';

import { HomeComponent } from "./pages/home/home.component";
import {AngularFireModule} from "@angular/fire/compat";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { MisurazioniComponent } from "./pages/misurazioni/misurazioni.component";

import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';

import { NotLoggedHomeComponent } from "./components/home/not_logged.component";
import { AuthHttpInterceptor, AuthModule } from "@auth0/auth0-angular";
import { NavBarComponent } from "./components/navbar/navbar.component";



export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    HomeComponent,
    MisurazioniComponent,
    NotLoggedHomeComponent
  ],
  imports: [
    BrowserModule,
    ChartModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    StyleClassModule,
    BrowserAnimationsModule,
    ButtonModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.ERROR,
      disableConsoleLogging: false,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
     
    }),
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.auth0.audience,
      },
      httpInterceptor: {
        
        allowedList: [
         // `${environment.apiUrl}` + "security/*",
        ],
      }
    }),
    LottieModule.forRoot({player: playerFactory}),

  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    MessageService,
    DialogService,
    //VerticalAuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
   
  ],
  bootstrap: [AppComponent],
  exports: [
    
  ]
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http);
}

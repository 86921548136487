import {AfterViewInit, Component, Inject, OnInit, ViewChild} from "@angular/core";

import {DOCUMENT} from "@angular/common";
import {NGXLogger} from "ngx-logger";
import {TabViewChangeEvent} from "primeng/tabview";
import {environment} from "../../../environments/environment";
import {AngularFirestore, AngularFirestoreCollection} from "@angular/fire/compat/firestore";
import { Router } from "@angular/router";
import { Timestamp, onSnapshot } from "firebase/firestore";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ConfirmationService, MessageService } from "primeng/api";
import { Misurazione_Firestore } from "src/app/models/firestore/misurazioneFirestore.model";
import { ChartModule } from 'primeng/chart';
import {AuthService} from "@auth0/auth0-angular";
import { HttpClient } from '@angular/common/http';

@Component({
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  providers: [DialogService, ConfirmationService],
})
export class HomeComponent implements OnInit, AfterViewInit {
  protected isLoading: boolean = true;
  public isReady: boolean = false;

  public misurazioni_punto:Misurazione_Firestore;

  ref: DynamicDialogRef;
  options: any;
  data: any;


  constructor(
    public auth: AuthService,
    private logger: NGXLogger,
    private firestore: AngularFirestore,
    private router: Router,
    public dialogService: DialogService,
    private messageService:MessageService,
    @Inject(DOCUMENT) private doc: Document,
    private http: HttpClient,

  ) {
   
  }

  ngOnInit() {
    this.logger.info("ONINIT @ HOME");

    this.auth.user$.subscribe((user) => {
      if (user != null) {
        this.auth.getAccessTokenSilently().subscribe(
          (token) => {
            
            console.log('Access Token:', token);
          },
          (err) => {
            console.error('Error fetching access token', err);
          }
        );
      }});  
   
  
  }


  ngAfterViewInit() {
  }

  
  verificaToken(){
    const headers = {
      // 'Authorization': `Bearer ${token}`,
     };
 
     const httpOptions = {
       headers,
       reportProgress: true,
     };
     this.http.get(environment.apiUrl+"security/verify-token", httpOptions).subscribe(
      {
        next: (res) => {
          console.info("OK TEST CALL");
          console.info(res);
        },
        error: (error) => {
          if (error.status === 401) {
            console.error("Unauthorized: Token non valido o scaduto.");
          } else {
            console.error("Errore nella chiamata HTTP:", error);
          }
        },
        complete: () => {
          console.info("Chiamata completata.");
        }
      }
     );
  }



}

export const environment = {
  name: "TEST",
  apiUrl: "https://fragile-be.map-hosting.it/api/v1/",
  version: '0.0.5',
  auth0: {
    clientId: "vEXQdqyPTr8fDh5X00YMeHTn2RZp2NPQ",
    domain: "fragile.eu.auth0.com",
    audience: "https://fragile-be.map-hosting.it/",
    redirectUri: window.location.origin,
  },
  firebase: {
    apiKey: "AIzaSyBGeQqMn9HQVJohPQjF0SKZ9Rj92_9F6V8",
    authDomain: "gestore-catastale.firebaseapp.com",
    projectId: "gestore-catastale",
    storageBucket: "gestore-catastale.appspot.com",
    messagingSenderId: "172364245501",
    appId: "1:172364245501:web:d7090a7aff00b7f21c5194",
    measurementId: "G-8RDZG8RH79",
    collectionMisurazioni:"misurazioni_nuovo_modello",
  },
  storageBucket: "ates-energia.appspot.com",
  uploadFolderStorageBucket: "tecnocad/",
};

import { Injectable } from '@angular/core';
import { Utente } from '../models/utenti/utente.model';
import {AuthService, User} from "@auth0/auth0-angular";
import {UtentiService} from "./utenti.service";
import {lastValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userData: Utente;
  public isAdmin:boolean        = false;
  public isUtente:boolean        = false;
  public isCliente:boolean        = false;

  public ruoloStr: string;

  constructor(private auth: AuthService, private utenti: UtentiService) {
    // Inizializza i dati dell'utente se l'utente è già autenticato
    this.userData = null;
  }

  async loadSetUserInformation (user: User) {

    const idAuth0:string = user.sub.slice(user.sub.indexOf('|')+1);
    const utente = await lastValueFrom(this.utenti.getByIdAuth0(idAuth0));
    this.setUser(utente);

    
    this.ruoloStr = user["https://fragile.map-hosting.it/roles"][0];
    this.setRoleFromAuth0(this.ruoloStr);
    localStorage.setItem("isLoggedIn", "true");
    localStorage.setItem("ruolo", this.ruoloStr);
    localStorage.setItem("idUtente", user.sub.slice(user.sub.indexOf('|') + 1));
    localStorage.setItem("utente", user.name);

  }

  setUser(user: Utente) {
    this.userData = user;
  }

  public setRoleFromAuth0(role:string){
    if(role==='ADMIN'){
        this.isAdmin = true;
    }else if(role==='UTENTE'){
        this.isUtente = true;
    }else if(role==='CLIENTE'){
        this.isCliente = true;
    }
  }


  getUser(): Utente {
    return this.userData;
  }
}

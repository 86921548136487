import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { NGXLogger } from "ngx-logger";
import { MessageService } from "primeng/api";
import {
  DialogService,
} from "primeng/dynamicdialog";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";

interface Release {
  name: string;
  code: string;
}

@Component({
  selector: "home_not_logged",
  templateUrl: "./not_logged.component.html",
  styleUrls: ["./not_logged.component.css"],
})
export class NotLoggedHomeComponent implements OnInit {

  lottieConfig: object;
  lottieConfigTBD: object;
  anim: any;

  constructor(
    public dialogService: DialogService,
    @Inject(DOCUMENT) private doc: Document,
    private logger: NGXLogger,
    public auth: AuthService,
  ) {
    this.lottieConfig = {
      path: '/assets/animations/ates-home.json',
      autoplay: true,
      loop: true,
      renderer: 'canvas',
    };
    this.lottieConfigTBD = {
      path: '/assets/animations/ates-home.json',
      autoplay: true,
      loop: true,
      renderer: 'canvas',
    };
  }

  ngOnInit(): void {
    this.logger.info("NG INIT @ NOT LOGGED COMPONENT");
  }

  loginWithRedirect() {
    this.auth.loginWithRedirect().subscribe((res) => {
    });
  }

  animationCreated(animation: any): void {
    this.anim = animation;
  }

  goToVertical(){

  }

}

<div class="p-1 md:p-1 lg:p-1" >
  <nav-bar style="z-index: 1000"></nav-bar>

    <div *ngIf="auth.user$ | async as user ; else elseBlock">
    </div>
    <ng-template #elseBlock>
        <home_not_logged></home_not_logged>
      </ng-template>
    
</div>

